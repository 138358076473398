import { ClerkProvider, SignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { AnalyticsProvider } from "../AnalyticsProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/browser";
import { useIsDesktop } from "../hooks/useIsDesktop";
import NeutralinoShortcuts from "../desktop/NeutralinoShortcuts";

import usePartySocket from "partysocket/react";

import { OrganizationCheck } from "./OrganizationCheck";
import { LogOutTracker } from "./LogOutTracker";
import { UserIdentifier } from "./UserIdentifier";
import { CallManager } from "./CallManager";
import { Toaster } from "@/components/ui/toaster";
import NeutralinoUpdater from "../desktop/NeutralinoUpdater";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  AUTH_SERVICE_URL,
  CLERK_PUBLISHABLE_KEY,
  POSTHOG_ENABLED,
  POSTHOG_KEY,
  SENTRY_DSN,
} from "./../../publicEnv";
import { NeutralinoProvider } from "./Neutralino/NeutralinoContextProvider";
import { TooltipProvider } from "@/components/ui/tooltip";
const queryClient = new QueryClient();

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

function ErrorFallback({ error }: { error: unknown }) {
  const errorMessage =
    error instanceof Error ? error.message : "An unknown error occurred";

  return (
    <div>
      <Card className="p-8">
        <h1 className="mb-4 text-2xl">Sorry, something went wrong</h1>
        <p className="mb-8">Error: {errorMessage}</p>
        <Button onClick={() => window.location.reload()}>Reload</Button>
      </Card>
    </div>
  );
}

export function App({ partyKitHost }: { partyKitHost: string }) {
  const isDesktop = useIsDesktop();
  const partySocket = usePartySocket({
    host: partyKitHost,
    room: "waiting-room",
  });

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <NeutralinoProvider>
        <QueryClientProvider client={queryClient}>
          <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
            <AnalyticsProvider
              posthogKey={POSTHOG_KEY}
              posthogEnabled={POSTHOG_ENABLED}
            >
              <TooltipProvider>
                <main className="min-h-screen">
                  <SignedOut>
                    <LogOutTracker />
                    <div className="flex items-center justify-center min-h-screen">
                      <SignIn />
                    </div>
                  </SignedOut>
                  <SignedIn>
                    <UserIdentifier />

                    <OrganizationCheck>
                      <CallManager
                        host={partyKitHost}
                        partySocket={partySocket}
                        authServiceUrl={AUTH_SERVICE_URL}
                      />
                    </OrganizationCheck>
                    {isDesktop && <NeutralinoUpdater />}
                  </SignedIn>
                </main>
              </TooltipProvider>
              <Toaster />
            </AnalyticsProvider>
          </ClerkProvider>
        </QueryClientProvider>
        {isDesktop && <NeutralinoShortcuts />}
      </NeutralinoProvider>
    </Sentry.ErrorBoundary>
  );
}
