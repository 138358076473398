import type { StatusCode } from "party/types";
import { PartyKitServerConnectionStatus } from "./useCallDisplay";
import { Button } from "@/components/ui/button";

export interface CallStatusProps {
  status?: StatusCode;
  handleRequestBotInfo: () => void;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
}

export function CallStatus({
  status,
  handleRequestBotInfo,
  partyKitServerConnectionStatus,
}: Readonly<CallStatusProps>) {
  const formatStatus = (status: string): string => {
    return status
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case "starting_up":
        return "bg-gray-500";
      case "call_connected":
      case "in_call_recording":
      case "audio_unmuted":
      case "video_unmuted":
        return "bg-green-500";
      case "call_connecting":
      case "audio_muted":
      case "video_muted":
      case "in_call_not_recording":
      case "reconnecting":
        return "bg-orange-500";
      case "call_failed":
      case "error":
      case "call_disconnected":
      case "disconnected":
        return "bg-red-500";
      case "ready":
        return "bg-gray-500";
      default:
        return "bg-gray-500";
    }
  };

  let displayStatus: string;
  let dotColor: string;

  if (
    partyKitServerConnectionStatus === "disconnected" ||
    partyKitServerConnectionStatus === "reconnecting"
  ) {
    displayStatus = formatStatus(partyKitServerConnectionStatus);
    dotColor = getStatusColor(partyKitServerConnectionStatus);
  } else {
    displayStatus = status ? formatStatus(status) : "Waiting";
    dotColor = status ? getStatusColor(status) : "bg-gray-500";
  }

  return (
    <Button size="sm" variant="ghost" onClick={() => handleRequestBotInfo()}>
      <div className={`w-2 h-2 rounded-full ${dotColor} mr-2`}></div>
      <span>{displayStatus}</span>
    </Button>
  );
}
