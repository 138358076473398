import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { TriangleAlert, XCircle } from "lucide-react";

export function ErrorNotice({
  error,
  clearError,
}: Readonly<{
  error: string;
  clearError?: () => void;
}>) {
  return (
    <Card className="p-2 border-red-600">
      <div className="flex items-center justify-between">
        <div className="flex-grow">
          <TriangleAlert
            size={16}
            className="inline-block ml-2 -mt-1 mr-2 text-red-600"
          />{" "}
          {error}
        </div>
        <div className="flex-shrink-0">
          <Button
            variant={"link"}
            onClick={() => {
              clearError?.();
            }}
          >
            <XCircle size={16} />
          </Button>
        </div>
      </div>
    </Card>
  );
}
