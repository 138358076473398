import React, { createContext, useContext, useState, useEffect } from "react";
import posthog from "posthog-js";

interface AnalyticsContextType {
  trackEvent: (eventName: string, properties?: Record<string, any>) => void;
  identify: (userId: string, userProperties?: Record<string, any>) => void;
  reset: () => void;
}

const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};

interface AnalyticsProviderProps {
  children: React.ReactNode;
  posthogKey: string | null;
  posthogEnabled: string | null;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
  posthogKey,
  posthogEnabled,
}) => {
  console.log("AnalyticsProvider", posthogKey, posthogEnabled);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (posthogKey && posthogEnabled === "true" && !isInitialized) {
      console.log("Analytics: Posthog is enabled");
      posthog.init(posthogKey, {
        api_host: "https://eu.i.posthog.com",
        person_profiles: "identified_only",
      });
      setIsInitialized(true);
    }
    if (posthogKey && posthogEnabled === "false" && !isInitialized) {
      console.log("Analytics: Posthog is disabled");
    }
  }, [posthogKey, posthogEnabled, isInitialized]);

  const trackEvent = (eventName: string, properties?: Record<string, any>) => {
    if (posthogEnabled === "true") {
      posthog.capture(eventName, properties);
    } else {
      console.log(`Analytics event: ${eventName}`, properties);
    }
  };

  const identify = (userId: string, userProperties?: Record<string, any>) => {
    if (posthogEnabled === "true") {
      posthog.identify(userId, userProperties);
    } else {
      console.log(`Analytics identify: ${userId}`, userProperties);
    }
  };

  const reset = () => {
    if (posthogEnabled === "true") {
      posthog.reset();
    } else {
      console.log("Analytics reset");
    }
  };

  return (
    <AnalyticsContext.Provider value={{ trackEvent, identify, reset }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
