import { useEffect, useRef, useCallback } from "react";
import { Card } from "@/components/ui/card";
import { SmoothScrollArea } from "@/components/ui/smooth-scroll-area";
import {
  CheckCircleIcon,
  MessageCircle,
  ChevronUp,
  ChevronDown,
  Locate,
} from "lucide-react";
import type { PlaybookProgress, PlaybookScriptItem } from "party/types";
import { Button } from "@/components/ui/button";
import { CallDisplayMode } from "./CallDisplayUI";

export type PlaybookScriptDisplayProps = {
  currentScriptItem?: PlaybookScriptItem;
  playbook: PlaybookProgress;
  handleCompleteScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleSetCurrentScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  completeCall: () => void;
  setActiveMode: (mode: CallDisplayMode) => void;
};

interface SmoothScrollAreaElement extends HTMLDivElement {
  smoothScrollTo: (options?: ScrollToOptions) => void;
}

export const PlaybookScriptItemNextPrev = ({
  onPrevious,
  onNext,
  onLocate,
}: {
  onPrevious: () => void;
  onNext: () => void;
  onLocate: () => void;
}) => {
  return (
    <div className="flex flex-row space-x-2">
      <Button variant="ghost" className="px-2 py-2 h-auto" onClick={onPrevious}>
        <ChevronUp size={12} />
      </Button>
      <Button variant="ghost" className="px-2 py-2 h-auto" onClick={onLocate}>
        <Locate size={12} />
      </Button>
      <Button variant="ghost" className="px-2 py-2 h-auto" onClick={onNext}>
        <ChevronDown size={12} />
      </Button>
    </div>
  );
};

export function PlaybookScriptDisplay({
  playbook,
  currentScriptItem,
  handleCompleteScriptItem,
  handleSetCurrentScriptItem,
  completeCall,
  setActiveMode,
}: Readonly<PlaybookScriptDisplayProps>) {
  const scrollAreaRef = useRef<SmoothScrollAreaElement>(null);
  const currentItemRef = useRef<HTMLDivElement>(null);

  const scrollToCurrentItem = useCallback(() => {
    if (currentItemRef.current && scrollAreaRef.current) {
      const scrollArea = scrollAreaRef.current;
      const currentItem = currentItemRef.current;

      const scrollAreaHeight = scrollArea.clientHeight;
      const itemTop = currentItem.offsetTop;
      const itemHeight = currentItem.clientHeight;

      // Calculate the scroll position to center the current item
      const scrollPosition = itemTop - scrollAreaHeight / 2 + itemHeight / 2;

      scrollArea.smoothScrollTo({ top: scrollPosition });
    }
  }, []);

  useEffect(() => {
    // Use a small timeout to ensure the DOM has updated
    const timeoutId = setTimeout(scrollToCurrentItem, 0);

    return () => clearTimeout(timeoutId);
  }, [currentScriptItem, scrollToCurrentItem]);

  useEffect(() => {
    // If there's no current script item, set it to the first item of the first section
    if (
      !currentScriptItem &&
      playbook.sections.length > 0 &&
      playbook.sections[0].scriptItems.length > 0
    ) {
      handleSetCurrentScriptItem(playbook.sections[0].scriptItems[0]);
    }
  }, [playbook, currentScriptItem, handleSetCurrentScriptItem]);

  const handlePrevious = useCallback(() => {
    const allScriptItems = playbook.sections.flatMap(
      (section) => section.scriptItems
    );
    const currentIndex = allScriptItems.findIndex(
      (item) => item.id === currentScriptItem?.id
    );
    if (currentIndex > 0) {
      handleSetCurrentScriptItem(allScriptItems[currentIndex - 1]);
    }
  }, [playbook, currentScriptItem, handleSetCurrentScriptItem]);

  const handleNext = useCallback(() => {
    const allScriptItems = playbook.sections.flatMap(
      (section) => section.scriptItems
    );
    const currentIndex = allScriptItems.findIndex(
      (item) => item.id === currentScriptItem?.id
    );
    if (currentIndex < allScriptItems.length - 1) {
      handleSetCurrentScriptItem(allScriptItems[currentIndex + 1]);
    }
  }, [playbook, currentScriptItem, handleSetCurrentScriptItem]);

  const currentSection = playbook?.sections?.find(
    (section) => section.id === playbook.currentSectionId
  );

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-row space-x-4 w-full mb-2">
        <h2 className="font-semibold flex-grow pt-1 pl-2">
          {currentSection ? currentSection.name : "Loading…"}
        </h2>
        <div>
          <PlaybookScriptItemNextPrev
            onPrevious={handlePrevious}
            onNext={handleNext}
            onLocate={scrollToCurrentItem}
          />
        </div>
        <div className="-mt-1">
          <Button size="sm" variant="secondary" onClick={completeCall}>
            Complete call
          </Button>
        </div>
      </div>
      <Card className="border border-gray-300 flex-grow overflow-hidden flex flex-col">
        <SmoothScrollArea ref={scrollAreaRef} className="h-full" smoothScroll>
          <div>
            {playbook?.sections?.flatMap((section) =>
              section.scriptItems.map((item) => (
                <div
                  key={item.id}
                  ref={
                    item.id === currentScriptItem?.id ? currentItemRef : null
                  }
                  className={`p-2 border-b border-b-gray-100 ${
                    item.completed ? "text-green-500" : "text-gray-900"
                  } ${item.id === currentScriptItem?.id ? "bg-yellow-100" : "hover:bg-yellow-50"}`}
                >
                  <div className="flex items-center space-x-2">
                    <div className="flex-shrink-0">
                      {item.completed ? (
                        <div className="px-2">
                          <CheckCircleIcon size={16} />
                        </div>
                      ) : (
                        <Button
                          variant={"ghost"}
                          className="px-2"
                          onClick={() => {
                            handleCompleteScriptItem(item);
                          }}
                        >
                          <MessageCircle size={16} />
                        </Button>
                      )}
                    </div>
                    <div className="pr-4">
                      <div
                        className={
                          item.id === currentScriptItem?.id
                            ? "font-medium"
                            : "font-medium"
                        }
                      >
                        <button
                          onClick={() => handleSetCurrentScriptItem(item)}
                          className="inline text-left text-gray-700 hover:text-black"
                        >
                          {item.text}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </SmoothScrollArea>
      </Card>
    </div>
  );
}
