import * as React from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

import { cn } from "@/lib/utils";

interface SmoothScrollAreaProps
  extends React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> {
  smoothScroll?: boolean;
}

interface SmoothScrollAreaElement extends HTMLDivElement {
  smoothScrollTo: (options?: ScrollToOptions) => void;
}

const SmoothScrollArea = React.forwardRef<
  SmoothScrollAreaElement,
  SmoothScrollAreaProps
>(({ className, children, smoothScroll = false, ...props }, ref) => {
  const viewportRef = React.useRef<HTMLDivElement>(null);

  React.useImperativeHandle(ref, () => {
    const element = viewportRef.current as SmoothScrollAreaElement;
    element.smoothScrollTo = (options?: ScrollToOptions) => {
      if (viewportRef.current) {
        if (smoothScroll) {
          viewportRef.current.scrollTo({ ...options, behavior: "smooth" });
        } else {
          viewportRef.current.scrollTo(options || {});
        }
      }
    };
    return element;
  }, [smoothScroll]);

  return (
    <ScrollAreaPrimitive.Root
      className={cn("relative overflow-hidden", className)}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        ref={viewportRef}
        className="h-full w-full rounded-[inherit]"
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  );
});
SmoothScrollArea.displayName = "SmoothScrollArea";

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = "vertical", ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      "flex touch-none select-none transition-colors",
      orientation === "vertical" &&
        "h-full w-2.5 border-l border-l-transparent p-[1px]",
      orientation === "horizontal" &&
        "h-2.5 flex-col border-t border-t-transparent p-[1px]",
      className
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-border" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { SmoothScrollArea, ScrollBar };
