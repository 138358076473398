import type {
  AILatencyMetrics,
  AIQuestionAnswer,
  AIStreamingStatus,
  PartialAIResponse,
} from "party/types";
import {
  AiHelpCard,
  HandleGetHelpFunction,
  type HandleGetHelpFunctionArgs,
} from "./AiHelpCard";
import { useEffect, useState } from "react";
import { AiHelpAsk } from "./AiHelpAsk";

export type AiHelp = PartialAIResponse | AIQuestionAnswer;

export interface AiHelpBrowserProps {
  history?: AiHelp[];
  latencyMetrics?: AILatencyMetrics;
  handleGetHelp: HandleGetHelpFunction;
  currentAiHelpRequest: HandleGetHelpFunctionArgs | undefined;
  isStreaming: boolean;
  streamingStatus: AIStreamingStatus | undefined;
  streamingResponse: PartialAIResponse | null;
}

export function AiHelpBrowser({
  history = [],
  latencyMetrics,
  handleGetHelp,
  currentAiHelpRequest,
  isStreaming,
  streamingStatus,
  streamingResponse,
}: Readonly<AiHelpBrowserProps>) {
  const [currentHelp, setCurrentHelp] = useState<AiHelp | null>(null);
  const [currentIndex, setCurrentIndex] = useState(history.length - 1);

  // When history updates, update the current index and current help
  useEffect(() => {
    if (history.length) {
      setCurrentIndex(history.length - 1);
      setCurrentHelp(history[history.length - 1]);
    }
  }, [history]);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentHelp(history[currentIndex - 1]);
    }
  };

  const handleNext = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentHelp(history[currentIndex + 1]);
    }
  };

  return (
    <div className="flex items-center space-y-4 flex-col">
      <AiHelpCard
        help={streamingResponse ?? currentHelp}
        latencyMetrics={latencyMetrics}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        currentIndex={currentIndex}
        historyLength={history.length}
        handleGetHelp={handleGetHelp}
        currentAiHelpRequest={currentAiHelpRequest}
        isStreaming={isStreaming}
        streamingStatus={streamingStatus}
        streamingResponse={streamingResponse}
      />
    </div>
  );
}
