import "./globals.css";
import { createRoot } from "react-dom/client";
import { App } from "./components/App";
import React from "react";
import Neutralino, { init } from "@neutralinojs/lib";
import { isDesktop } from "./hooks/useIsDesktop";
import { SPECIFIED_PARTYKIT_HOST } from "./../publicEnv";

let host = SPECIFIED_PARTYKIT_HOST;
if (typeof PARTYKIT_HOST !== "undefined") {
  host = PARTYKIT_HOST;
}
console.log("Using partykit host", host);
createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <App partyKitHost={host} />
  </React.StrictMode>
);

if (isDesktop()) {
  console.log("Initializing Neutralino...", Neutralino);
  init();
}
