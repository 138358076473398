import React, { createContext, useContext, useEffect, useState } from "react";
import { window as neutralinoWindow } from "@neutralinojs/lib";

interface NeutralinoContextValue {
  isAlwaysOnTop: boolean;
  toggleAlwaysOnTop: () => void;
}

const NeutralinoContext = createContext<NeutralinoContextValue>({
  isAlwaysOnTop: true,
  toggleAlwaysOnTop: () => {},
});

export const useNeutralino = () => useContext(NeutralinoContext);

export const NeutralinoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAlwaysOnTop, setIsAlwaysOnTop] = useState(true);

  useEffect(() => {
    // Set initial alwaysOnTop state to true on launch
    neutralinoWindow.setAlwaysOnTop(true);
  }, []);

  const toggleAlwaysOnTop = async () => {
    const newState = !isAlwaysOnTop;
    await neutralinoWindow.setAlwaysOnTop(newState);
    setIsAlwaysOnTop(newState);
  };

  return (
    <NeutralinoContext.Provider value={{ isAlwaysOnTop, toggleAlwaysOnTop }}>
      {children}
    </NeutralinoContext.Provider>
  );
};
