import { FaGoogle, FaMicrosoft, FaVideo } from "react-icons/fa";
import { SiZoom } from "react-icons/si";

interface CallServiceIconProps {
  url: string;
}

export function CallServiceIcon({ url }: Readonly<CallServiceIconProps>) {
  let hostname;
  let pathname;
  try {
    const parsedUrl = new URL(url.trim());
    hostname = parsedUrl?.hostname;
    pathname = parsedUrl?.pathname;
  } catch (e) {
    console.error("Error parsing URL", e);
  }

  if (hostname?.includes("google")) {
    const roomId = pathname?.split("/").pop();
    return (
      <div className="flex items-center">
        <FaGoogle className="mr-2" />
        <span>{roomId}</span>
      </div>
    );
  } else if (hostname?.includes("zoom")) {
    const roomId = pathname?.split("/").pop();
    return (
      <div className="flex items-center">
        <SiZoom className="mr-2" />
        <span>{roomId}</span>
      </div>
    );
  } else if (hostname?.includes("teams.microsoft")) {
    const roomId = pathname?.split("/").pop();
    return (
      <div className="flex items-center">
        <FaMicrosoft className="mr-2" />
        <span>{roomId}</span>
      </div>
    );
  } else {
    // Default case for unsupported or unknown services
    return (
      <div className="flex items-center">
        <FaVideo className="mr-2" />
        <span>{pathname ?? "Unknown"}</span>
      </div>
    );
  }
}
