import { useMemo } from "react";

export function isDesktop() {
  if (window.location.protocol === "https:") {
    return false;
  }
  return !window.location.port.match(/^(80|443|1999)$/);
}

export function useIsDesktop() {
  return useMemo(() => {
    return isDesktop();
  }, []);
}
