import {
  useOrganization,
  OrganizationSwitcher,
  UserButton,
  CreateOrganization,
} from "@clerk/clerk-react";

export function OrganizationCheck({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const { organization } = useOrganization();

  if (!organization) {
    return (
      <div className="flex flex-col h-screen max-w-2xl m-auto">
        <div className="flex justify-between items-center w-full mb-4 p-4">
          <div>
            <OrganizationSwitcher />
          </div>
          <div className="text-right">
            <UserButton />
          </div>
        </div>
        <div className="flex justify-between items-center w-full mb-4 p-4">
          <div className="m-auto">
            <CreateOrganization />
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}
