"use client";

import { TrendingUp } from "lucide-react";
import { Bar, BarChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

export const description = "A mixed bar chart";

const chartData = [
  {
    measure: "question_answering",
    results: 275,
    fill: "#66aa88",
  },
  { measure: "script_completion", results: 200, fill: "#88aaaa" },
  { measure: "call_completion", results: 200, fill: "#aa6688" },
  { measure: "success_criteria", results: 187, fill: "#8866aa" },
];

const chartConfig = {
  results: {
    label: "Results",
  },
  question_answering: {
    label: "Answering",
    color: "hsl(var(--chart-1))",
  },
  script_completion: {
    label: "Completion",
    color: "hsl(var(--chart-2))",
  },
  call_completion: {
    label: "Responsiveness",
    color: "hsl(var(--chart-3))",
  },
  success_criteria: {
    label: "Success",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig;

export function CallResultsChart() {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg">Call breakdown</CardTitle>
        <CardDescription className="text-sm">
          How you performance breaks down
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig} className="h-32 w-full">
          <BarChart
            accessibilityLayer
            data={chartData}
            layout="vertical"
            margin={{
              left: 0,
            }}
          >
            <YAxis
              width={128}
              dataKey="measure"
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) =>
                chartConfig[value as keyof typeof chartConfig]?.label
              }
            />
            <XAxis dataKey="results" type="number" hide />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey="results" layout="vertical" radius={5} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      {/* <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing total visitors for the last 6 months
        </div>
      </CardFooter> */}
    </Card>
  );
}
