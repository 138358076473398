import { useCallDisplay } from "./useCallDisplay";
import { CallDisplayUI } from "./CallDisplayUI";

export function CallDisplay({
  host,
  roomId,
  leaveCall,
}: Readonly<{ roomId: string; leaveCall: () => void; host: string }>) {
  const callDisplayProps = useCallDisplay(roomId, host);
  const { reconnectAttempt, ...callDisplayUIProps } = callDisplayProps;
  return (
    <CallDisplayUI
      key={reconnectAttempt}
      {...callDisplayUIProps}
      leaveCall={leaveCall}
      host={host}
      roomId={roomId}
    />
  );
}
