import { useCallback, useState } from "react";
import { SettingsMenu } from "./SettingsMenu";
import { OrganisationKnowledgeManagerUI } from "./OrganisationKnowledgeManagerUI";
import PartySocket from "partysocket";
import { HelpCircle, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CallScriptManagerUI } from "./Script/CallScriptManagerUI";
import { DownloadAppUIWrapper } from "./Download/DownloadAppUI";
import { useIsDesktop } from "../hooks/useIsDesktop";
import { os as neutralinoOs } from "@neutralinojs/lib";
export type SettingsTab = "knowledge" | "script" | "download";
export const SettingsManagerUI = ({
  partySocket,
  onClose,
}: {
  onClose: () => void;
  partySocket: PartySocket;
}) => {
  const [activeMode, setActiveMode] = useState<SettingsTab>("knowledge");
  const isDesktop = useIsDesktop();
  const goToHelp = useCallback(() => {
    const url = "https://intercom.help/frontier-ai/en";
    if (isDesktop) {
      neutralinoOs.open(url);
    } else {
      window.open(url, "_blank");
    }
  }, [isDesktop]);
  return (
    <div className="flex flex-col h-full max-h-screen overflow-hidden">
      <div className="grid grid-cols-3 items-center w-full p-2">
        <div></div>
        <h2 className="text-center text-md font-semibold">Settings</h2>
        <div className="flex justify-end space-x-2">
          <Button onClick={goToHelp} variant="ghost" size="icon">
            <HelpCircle className="h-6 w-6" />
          </Button>
          <Button onClick={onClose} variant="ghost" size="icon">
            <X className="h-6 w-6" />
          </Button>
        </div>
      </div>

      <div className="flex flex-col flex-grow overflow-hidden">
        <div className="flex justify-center">
          <SettingsMenu activeMode={activeMode} setActiveMode={setActiveMode} />
        </div>
        <div className="flex-grow overflow-hidden">
          {activeMode === "knowledge" && (
            <OrganisationKnowledgeManagerUI partySocket={partySocket} />
          )}
          {activeMode === "script" && (
            <CallScriptManagerUI partySocket={partySocket} />
          )}
          {activeMode === "download" && <DownloadAppUIWrapper />}
        </div>
      </div>
    </div>
  );
};
