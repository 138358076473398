import { useCallback, useState } from "react";
import usePartySocket from "partysocket/react";
import { PartyKitServerConnectionStatus } from "../components/useCallDisplay";

export function usePartySocketWithReconnect({
  host,
  room,
  party,
  onMessage,
}: {
  host: string;
  room: string;
  party: string;
  onMessage: (event: MessageEvent) => void;
}) {
  const [partyKitServerConnectionStatus, setPartyKitServerConnectionStatus] =
    useState<PartyKitServerConnectionStatus>("disconnected");
  const [reconnectAttempt, setReconnectAttempt] = useState(0);

  const socket = usePartySocket({
    host,
    room,
    party,
    onOpen() {
      console.log("WebSocket connection opened for room:", room);
      setPartyKitServerConnectionStatus("connected");
      setReconnectAttempt(0);
    },
    onClose() {
      console.warn("WebSocket connection closed for room:", room);
      setPartyKitServerConnectionStatus("disconnected");
      handleReconnect();
    },
    onError(error) {
      console.error("WebSocket error for room:", room, error);
    },
    onMessage,
  });

  const handleReconnect = useCallback(() => {
    setPartyKitServerConnectionStatus("reconnecting");
    const backoffTime = Math.min(1000 * 2 ** reconnectAttempt, 30000); // Max 30 seconds
    setTimeout(() => {
      setReconnectAttempt((prev) => prev + 1);
      socket.reconnect();
    }, backoffTime);
  }, [socket, reconnectAttempt]);

  return {
    socket,
    partyKitServerConnectionStatus,
    reconnectAttempt,
  };
}
