// DocumentUploader.tsx
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@radix-ui/react-label";
import { Upload, Loader2 } from "lucide-react";
import React, { useCallback, useState } from "react";

interface DocumentUploaderProps {
  onUpload: (files: File[]) => void;
  isUploading: boolean;
  fileInputRef: React.RefObject<HTMLInputElement>;
}

export function DocumentUploader({
  onUpload,
  isUploading,
  fileInputRef,
}: DocumentUploaderProps) {
  const [filesSelected, setFilesSelected] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleUpload = useCallback(() => {
    if (selectedFiles.length > 0) {
      onUpload(selectedFiles);
      setFilesSelected(false);
      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [selectedFiles, onUpload, fileInputRef]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const validFiles = Array.from(files).filter(
        (file) => file.type === "application/pdf"
      );
      setFilesSelected(validFiles.length > 0);
      setSelectedFiles(validFiles);

      if (validFiles.length === 0) {
        alert("Please select PDF files only.");
      }
    } else {
      setFilesSelected(false);
      setSelectedFiles([]);
    }
  };

  return (
    <div className="max-w-128">
      <h2 className="text-lg font-medium mb-4">AI Knowledge base</h2>
      <p className="text-sm text-gray-400 mb-4">
        Documents you upload here will be used in Get AI Help to answer
        questions.
      </p>
      <div className="mb-2">
        <Label htmlFor="file">Upload PDFs</Label>
      </div>
      <div className="flex gap-4 w-full">
        <div className="flex-grow">
          <Input
            id="file"
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            disabled={isUploading}
            ref={fileInputRef}
            multiple
          />
        </div>
        <Button
          variant="default"
          onClick={handleUpload}
          disabled={isUploading || !filesSelected}
        >
          {isUploading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Uploading...
            </>
          ) : (
            <>
              <Upload className="mr-2 h-4 w-4" />
              Upload
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
