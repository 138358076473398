import { useAnalytics } from "../AnalyticsProvider";
import { useEffect } from "react";
import { useSessionStorage } from "usehooks-ts";

export function LogOutTracker() {
  const { trackEvent, reset } = useAnalytics();
  const [hadSession, setHadSession] = useSessionStorage("hadSession", false);

  useEffect(() => {
    if (hadSession) {
      trackEvent("logged_out");
      reset();
      setHadSession(false);
      sessionStorage.removeItem("currentBotSession");
      sessionStorage.removeItem("hasLoggedInThisSession");
    }
  }, [hadSession, setHadSession]);

  return null;
}
