import { Button } from "@/components/ui/button";
import { useNeutralino } from "./NeutralinoContextProvider";
import { Pin, PinOff } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export const ToggleAlwaysOnTop = () => {
  const { isAlwaysOnTop, toggleAlwaysOnTop } = useNeutralino();

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button onClick={toggleAlwaysOnTop} variant="outline" size="icon">
          {isAlwaysOnTop ? (
            <Pin className="h-4 w-4" />
          ) : (
            <PinOff className="h-4 w-4" />
          )}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        {isAlwaysOnTop
          ? "Disable always on top mode"
          : "Enable always on top mode"}
      </TooltipContent>
    </Tooltip>
  );
};
