import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Table, TableBody, TableRow, TableCell } from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { FileText, Edit, Loader2, Check, X } from "lucide-react";
import { CallScript } from "./types/CallScript";

interface CallScriptListProps {
  callScripts: CallScript[];
  isLoading: boolean;
  isError: boolean;
  onEdit: (id: string) => void;
  onUpdateTitle: (script: CallScript) => void;
}

export function CallScriptList({
  callScripts,
  isLoading,
  isError,
  onEdit,
  onUpdateTitle,
}: CallScriptListProps) {
  const [editingTitleId, setEditingTitleId] = useState<string | null>(null);
  const [editedTitle, setEditedTitle] = useState("");

  const handleTitleClick = (script: CallScript) => {
    setEditingTitleId(script.id);
    setEditedTitle(script.title);
  };

  const handleTitleSave = (script: CallScript) => {
    onUpdateTitle({ ...script, title: editedTitle });
    setEditingTitleId(null);
  };

  const handleTitleCancel = () => {
    setEditingTitleId(null);
  };

  return (
    <div className="flex flex-col h-full">
      <Card className="flex-grow overflow-hidden">
        <ScrollArea className="h-full">
          <div>
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader2 className="h-8 w-8 animate-spin" />
              </div>
            ) : isError ? (
              <div className="flex justify-center items-center h-full text-red-500">
                Error loading call scripts. Please try again.
              </div>
            ) : callScripts.length === 0 ? (
              <div className="flex justify-center items-center h-full text-gray-500 text-sm">
                <div className="py-8">Create your first call script</div>
              </div>
            ) : (
              <Table>
                <TableBody>
                  {callScripts.map((script) => (
                    <TableRow key={script.id}>
                      <TableCell className="w-1/2">
                        <div className="flex items-center">
                          <FileText className="w-4 h-4 mr-2 flex-shrink-0" />
                          {editingTitleId === script.id ? (
                            <div className="flex items-center flex-grow">
                              <Input
                                value={editedTitle}
                                onChange={(e) => setEditedTitle(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleTitleSave(script);
                                  }
                                }}
                                className="mr-2 flex-grow"
                              />
                              <div className="flex-shrink-0">
                                <Button
                                  size="sm"
                                  variant="ghost"
                                  onClick={() => handleTitleSave(script)}
                                  className="mr-1"
                                >
                                  <Check size={16} />
                                </Button>
                                <Button
                                  size="sm"
                                  variant="ghost"
                                  onClick={handleTitleCancel}
                                >
                                  <X size={16} />
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <span
                              className="cursor-pointer hover:underline flex-grow"
                              onClick={() => handleTitleClick(script)}
                            >
                              {script.title}
                            </span>
                          )}
                        </div>
                      </TableCell>
                      <TableCell className="w-1/3 text-right text-gray-500 text-xs">
                        Created: {new Date(script.createdAt).toLocaleString()}
                      </TableCell>
                      <TableCell className="text-right w-1/6">
                        <Button
                          variant="secondary"
                          onClick={() => onEdit(script.id)}
                        >
                          <Edit size={16} className="mr-2" />
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </ScrollArea>
      </Card>
    </div>
  );
}
