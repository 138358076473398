import { z } from "zod";

export const CallScriptSectionSchema = z.object({
  title: z.string(),
  items: z.array(z.string()),
});

export const CallScriptSchema = z.object({
  id: z.string(),
  title: z.string(),
  sections: z.array(CallScriptSectionSchema).optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type CallScript = z.infer<typeof CallScriptSchema>;
export type CallScriptSection = z.infer<typeof CallScriptSectionSchema>;
