import z from "zod";

export const KnowledgeDocumentSchema = z.object({
  id: z.string(),
  name: z.string(),
  key: z.string(),
  uploadedAt: z.string(),
  status: z.string().optional(),
  progress: z.number().optional(),
});

export type KnowledgeDocument = z.infer<typeof KnowledgeDocumentSchema>;

export const KnowledgeApiResponseSchema = z.union([
  z.array(KnowledgeDocumentSchema),
  z.object({ message: z.string() }),
]);

export const KnowledgeDocumentWithTaskSchema = KnowledgeDocumentSchema.extend({
  task: z
    .object({
      status: z.enum([
        "pending",
        "uploading",
        "processing",
        "completed",
        "error",
      ]),
      progress: z.number(),
    })
    .optional(),
});

export type KnowledgeDocumentWithTask = z.infer<
  typeof KnowledgeDocumentWithTaskSchema
>;
