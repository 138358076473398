import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@radix-ui/react-label";
import { Plus, Loader2 } from "lucide-react";
import React, { useState } from "react";

interface CallScriptCreatorProps {
  onCreate: (title: string) => void;
  isCreating: boolean;
}

export function CallScriptCreator({
  onCreate,
  isCreating,
}: CallScriptCreatorProps) {
  const [title, setTitle] = useState("");

  const handleCreate = () => {
    if (title.trim()) {
      onCreate(title.trim());
      setTitle("");
    }
  };

  return (
    <div className="max-w-128">
      <h2 className="text-lg font-medium mb-4">Call scripts</h2>
      <p className="text-sm text-gray-400 mb-4">
        Define the scripts that members of your organisation should use during
        their calls.
      </p>
      <div className="mb-2">
        <Label htmlFor="script-title">Add a new script</Label>
      </div>
      <div className="flex gap-4 w-full">
        <div className="flex-grow">
          <Input
            id="script-title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            disabled={isCreating}
            placeholder="Enter script title"
          />
        </div>
        <Button
          variant="default"
          onClick={handleCreate}
          disabled={isCreating || !title.trim()}
        >
          {isCreating ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Creating...
            </>
          ) : (
            <>
              <Plus className="mr-2 h-4 w-4" />
              Create
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
