import { z } from "zod";
import { TranscriptUpdateSchema } from "./types";

export const SpeakerSchema = z.object({
  id: z.number(),
  role: z.enum(["Salesperson", "Customer"]),
});

export type Speaker = z.infer<typeof SpeakerSchema>;

export const QuestionSchema = z.object({
  id: z
    .string()
    .describe(
      "The ID of the question. This is the start_time of the question as an integer, in a string."
    ),
  speaker_id: z.number().describe("The ID of the speaker asking the question"),
  question: z
    .string()
    .describe(
      "A summarised version of the question. You might need to add some context to make it clear what the question is, given preceding text in the transcript. Eg. 'How fast does the Cybertruck go' versus 'How fast does it go?'"
    ),
  start_time: z
    .number()
    .describe(
      "The start time of the question in the transcript, an integer in seconds"
    ),
  end_time: z
    .number()
    .describe(
      "The end time of the question in the transcript, an integer in seconds"
    ),
  context: z
    .string()
    .describe(
      "A summary of the relevant context, based on the preceding text in the transcript. This will act as a reminder about what the question relates to and should be concise, businesslike, and informative. Do not add additional text about the salesperson or customer. For example, 'Asking about the delivery time of the product.'"
    ),
  isAnswered: z.boolean().describe("Whether the question has been answered"),
});

export type Question = z.infer<typeof QuestionSchema>;

export const QuestionsClientMessageSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("SPEAKERS"),
    payload: z.array(SpeakerSchema),
  }),
  z.object({
    type: z.literal("TRANSCRIPT_UPDATE"),
    payload: TranscriptUpdateSchema,
  }),
  z.object({
    type: z.literal("FULL_TRANSCRIPT"),
    payload: z.array(TranscriptUpdateSchema),
  }),
  z.object({
    type: z.literal("ANSWER_QUESTION"),
    payload: z.object({ questionId: z.string() }),
  }),
  z.object({
    type: z.literal("DETECTED_QUESTIONS"),
    payload: z.object({ questions: z.array(QuestionSchema) }),
  }),
]);

export type QuestionsClientMessage = z.infer<
  typeof QuestionsClientMessageSchema
>;

export const QuestionsServerMessageSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("QUESTION"),
    payload: QuestionSchema,
  }),
  z.object({
    type: z.literal("QUESTIONS"),
    payload: z.array(QuestionSchema),
  }),
]);

export type QuestionsServerMessage = z.infer<
  typeof QuestionsServerMessageSchema
>;
