import { Card } from "@/components/ui/card";
import { Table, TableBody, TableRow, TableCell } from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Trash2, Loader2, File } from "lucide-react";
import { KnowledgeDocumentWithTask } from "./types/Document";
import { UploadTask } from "../useKnowledgeUploadQueue";

interface DocumentListProps {
  documents: KnowledgeDocumentWithTask[];
  isLoading: boolean;
  isError: boolean;
  onDelete: (id: string) => void;
  isDeleting: boolean;
}

export function DocumentList({
  documents,
  isLoading,
  isError,
  onDelete,
  isDeleting,
}: DocumentListProps) {
  return (
    <div className="flex flex-col h-full">
      <Card className="flex-grow overflow-hidden">
        <ScrollArea className="h-full">
          <div>
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader2 className="h-8 w-8 animate-spin" />
              </div>
            ) : isError ? (
              <div className="flex justify-center items-center h-full text-red-500">
                Error loading documents. Please try again.
              </div>
            ) : documents.length === 0 ? (
              <div className="flex justify-center items-center h-full text-gray-500 text-sm">
                <div className="py-8">Upload your first document</div>
              </div>
            ) : (
              <Table>
                <TableBody>
                  {documents.map((document) => (
                    <DocumentRow
                      key={document.id}
                      document={document}
                      isDeleting={isDeleting}
                      onDelete={onDelete}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </ScrollArea>
      </Card>
    </div>
  );
}

const DocumentRow = ({
  document,
  isDeleting,
  onDelete,
}: {
  document: KnowledgeDocumentWithTask;
  isDeleting: boolean;
  onDelete: (id: string) => void;
}) => {
  return (
    <TableRow>
      <TableCell className="w-1/2">
        <File className="w-4 h-4 mr-2 inline-block -mt-1" />
        {document.name}
      </TableCell>
      <TableCell className="w-1/3 text-right text-gray-500 text-xs">
        {document.task ? (
          <span className="flex items-center justify-end">
            {document.task.status === "uploading" ||
            document.task.status === "processing" ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                {document.task.status === "uploading"
                  ? "Uploading"
                  : "Processing"}{" "}
                ({document.task.progress.toFixed(2)}%)
              </>
            ) : document.task.status === "completed" ? (
              "Completed"
            ) : document.task.status === "error" ? (
              "Error"
            ) : null}
          </span>
        ) : (
          new Date(document.uploadedAt).toLocaleString()
        )}
      </TableCell>
      <TableCell className="text-right w-1/6">
        <Button
          variant="secondary"
          onClick={() => onDelete(document.key)}
          disabled={isDeleting}
        >
          <Trash2 size={16} />
        </Button>
      </TableCell>
    </TableRow>
  );
};
