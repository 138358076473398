export function CallManagerWrapper({
  children,
  verticalMargin = "lg:py-8",
}: Readonly<{
  children: React.ReactNode;
  verticalMargin?: string;
}>) {
  return (
    <div
      className={`lg:bg-slate-200 h-screen w-full ${verticalMargin} flex flex-col`}
    >
      <div className="flex-grow overflow-hidden">
        <div className="h-full max-w-2xl m-auto lg:shadow-md bg-white rounded-md">
          {children}
        </div>
      </div>
    </div>
  );
}
