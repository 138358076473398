import { useCallback, useEffect, useRef, useState } from "react";
import type { RecallBot } from "party/types";

type UseBotStatusPollingProps = {
  roomBot: RecallBot | null;
  onPoll: () => void;
  initialInterval?: number;
  maxAttempts?: number;
};

export function useBotStatusPolling({
  roomBot,
  onPoll,
  initialInterval = 2000,
  maxAttempts = 50,
}: UseBotStatusPollingProps) {
  const [pollInterval, setPollInterval] = useState(initialInterval);
  const shouldPollRef = useRef(false);
  const pollCountRef = useRef(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clearPollingTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const resetPolling = useCallback(() => {
    shouldPollRef.current = false;
    pollCountRef.current = 0;
    setPollInterval(initialInterval);
    clearPollingTimeout();
  }, [initialInterval, clearPollingTimeout]);

  const pollBotStatus = useCallback(() => {
    if (pollCountRef.current < maxAttempts && shouldPollRef.current) {
      clearPollingTimeout();
      timeoutRef.current = setTimeout(() => {
        console.log(`Polling bot status (attempt ${pollCountRef.current + 1})`);
        onPoll();
        pollCountRef.current += 1;
        setPollInterval((prev) => Math.min(prev * 1.25, 30000)); // Increase by 25%, max 30 seconds
        pollBotStatus(); // Continue polling
      }, pollInterval);
    }
  }, [maxAttempts, onPoll, pollInterval, clearPollingTimeout]);

  useEffect(() => {
    if (roomBot) {
      const latestStatus =
        roomBot.status_changes?.[roomBot.status_changes.length - 1]?.code;
      if (
        latestStatus === "ready" ||
        latestStatus === "in_waiting_room" ||
        latestStatus === "joining_call"
      ) {
        resetPolling();
        shouldPollRef.current = true;
        pollBotStatus();
      } else {
        resetPolling();
        console.log("Stopped polling bot status due to status change");
      }
    }

    return clearPollingTimeout;
  }, [roomBot, pollBotStatus, resetPolling, clearPollingTimeout]);

  useEffect(() => {
    if (pollCountRef.current === maxAttempts) {
      resetPolling();
      console.log(`Stopped polling bot status after ${maxAttempts} attempts`);
    }
  }, [maxAttempts, resetPolling]);

  return { isPolling: shouldPollRef.current };
}
