import { CallResultsChart } from "./CallResultsChart";
import { CallResultsMeasure } from "./CallResultsMeasure";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { CallDisplayMode } from "./CallDisplayUI";
import { CallSummary } from "./CallSummary";
import { SummaryOutput } from "party/logic/SummaryGenerator/SummaryGenerator";

export const CallResultsReport = ({
  setActiveMode,
  handleRequestSummary,
  summary,
}: {
  setActiveMode: (mode: CallDisplayMode) => void;
  handleRequestSummary: () => void;
  summary: SummaryOutput | null;
}) => (
  <Card>
    <CardContent className="w-full p-4 bg-slate-50">
      <div className="flex-grow flex flex-col space-y-4 w-full">
        <div className="flex flex-row space-x-4">
          <div className="flex-grow"></div>
          <div>
            <Button variant="secondary">View full report</Button>
          </div>
        </div>
        <CallResultsMeasure />
        <CallResultsChart />
        <CallSummary
          handleRequestSummary={handleRequestSummary}
          summary={summary}
        />
      </div>
    </CardContent>
  </Card>
);
