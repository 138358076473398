import { Button } from "@/components/ui/button";
import { APP_DOWNLOAD_HOST } from "./../../../publicEnv";
import { useEffect, useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";

// Define the type for the manifest data
type Manifest = {
  applicationId: string;
  version: string;
  resourcesURL: string;
  data: {
    mac_silicon: string;
    mac_intel: string;
    mac_universal: string;
    win: string;
  };
};

async function getManifest(): Promise<Manifest> {
  const res = await fetch(
    `https://${APP_DOWNLOAD_HOST}/app/v1/updates/manifest.json`
  );
  if (!res.ok) {
    throw new Error("Failed to fetch manifest");
  }
  const json = await res.json();
  console.log("Manifest response", json);
  return json;
}

export const DownloadAppUIWrapper = () => {
  return (
    <ScrollArea className="p-4 h-full">
      <DownloadAppUI />
    </ScrollArea>
  );
};

export const DownloadAppUI = () => {
  // Download the manifest
  const [manifest, setManifest] = useState<Manifest | null>(null);
  useEffect(() => {
    getManifest().then(setManifest);
    console.log("New manifest", manifest);
  }, []);
  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex-grow overflow-hidden">
        <div className="h-full flex flex-col space-y-8">
          <div className="flex flex-col space-y-4">
            <h2 className="text-lg font-medium">
              Download the Desktop Mac App
            </h2>
            <p className="text-sm text-gray-400">
              For the best experience with Frontier X, download and run the
              desktop app on your Mac.
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-md font-medium">
              Mac M series (Apple Silicon)
            </h3>
            <p className="text-sm text-gray-400">
              If you have a recent Mac with an M1, M2, or M3 processor use this
              version
            </p>
            <a href={manifest?.data.mac_silicon} download>
              <Button variant="default">Download Apple Silicon App</Button>
            </a>
          </div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-md font-medium">Older Mac (Intel)</h3>
            <p className="text-sm text-gray-400">
              If you have an older mac before 2020, use this version.
            </p>
            <a href={manifest?.data.mac_intel} download>
              <Button variant="default">Download Intel App</Button>
            </a>
          </div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-md font-medium">Universal</h3>
            <p className="text-sm text-gray-400">
              Works on all Macs but has a larger file size
            </p>
            <a href={manifest?.data.mac_universal} download>
              <Button variant="default">Download Universal App</Button>
            </a>
          </div>
          <div>
            <p className="text-sm text-gray-400">
              Not sure which version is best? Go to the Apple menu at the top
              left of your screen and select About This Mac. Look for the
              Processor line and it will tell you if you have an M series or
              Intel processor.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
