import { z } from "zod";
import { AxiomLogger } from "party/utils/axiom";
import { Room } from "partykit/server";
import OpenAI from "openai";
import {
  unansweredQuestionsSystemPrompt,
  unansweredQuestionsUserPrompt,
} from "./prompts/unansweredQuestions";
import {
  nextStepsSystemPrompt,
  nextStepsUserPrompt,
} from "./prompts/nextSteps";
import {
  salesPipelineStatusSystemPrompt,
  salesPipelineStatusUserPrompt,
} from "./prompts/salesPipelineStatus";
import {
  internalSummarySystemPrompt,
  createInternalSummaryUserPrompt,
} from "./prompts/internalSummary";
import {
  shareableSummarySystemPrompt,
  createShareableSummaryUserPrompt,
} from "./prompts/shareableSummary";
import { zodResponseFormat } from "openai/helpers/zod.mjs";
import { TranscriptUpdate } from "party/types";

export const ObjectWithItemsSchema = z.object({
  reasoning: z
    .string()
    .describe("The reasoning behind the categorization of the content"),
  detectedContent: z
    .string()
    .describe(
      "The detected content that led to the conclusion about how to categorize the content"
    ),
  items: z
    .array(z.string())
    .describe("The items that were detected in the content"),
});

export type ObjectWithItems = z.infer<typeof ObjectWithItemsSchema>;

const SalesPipelineStatusSchema = z.object({
  status: z.enum(["lead", "qualified", "closing", "closed"]),
});

export const SummaryOutputSchema = z.object({
  internalSummary: ObjectWithItemsSchema,
  unansweredQuestions: ObjectWithItemsSchema,
  nextSteps: ObjectWithItemsSchema,
  shareableSummary: z.string(),
  salesPipelineStatus: z.enum(["lead", "qualified", "closing", "closed"]),
});

export type SummaryOutput = z.infer<typeof SummaryOutputSchema>;

export class SummaryGenerator {
  constructor(
    private room: Room,
    private axiom: AxiomLogger,
    private openai: OpenAI
  ) {}

  private condenseTranscriptUpdates(transcriptUpdates: TranscriptUpdate[]): {
    condensedTranscript: { speakerId: number; allWords: string[] }[];
    transcript: string;
  } {
    const condensedTranscript: { speakerId: number; allWords: string[] }[] = [];
    let currentSpeakerId: number | null = null;
    let currentWords: string[] = [];

    for (const update of transcriptUpdates) {
      if (update.speaker_id !== currentSpeakerId) {
        if (currentSpeakerId !== null) {
          condensedTranscript.push({
            speakerId: currentSpeakerId,
            allWords: currentWords,
          });
        }
        currentSpeakerId = update.speaker_id;
        currentWords = [];
      }
      currentWords.push(...update.words.map((word) => word.text));
    }

    if (currentSpeakerId !== null) {
      condensedTranscript.push({
        speakerId: currentSpeakerId,
        allWords: currentWords,
      });
    }

    const transcript = condensedTranscript
      .map(({ speakerId, allWords }) => `${speakerId}: ${allWords.join(" ")}`)
      .join("\n");

    return { condensedTranscript, transcript };
  }

  async generateSummary(
    transcriptUpdates: TranscriptUpdate[],
    participants: string
  ): Promise<SummaryOutput | null> {
    try {
      this.axiom.log({
        event: "call.generate_summary",
        participants,
      });

      const { transcript } = this.condenseTranscriptUpdates(transcriptUpdates);

      const [unansweredQuestions, nextSteps, salesPipelineStatus] =
        await Promise.all([
          this.extractUnansweredQuestions(transcript),
          this.extractNextSteps(transcript),
          this.determineSalesPipelineStatus(transcript),
        ]);

      const [internalSummary, shareableSummary] = await Promise.all([
        this.generateInternalSummary(
          transcript,
          unansweredQuestions,
          nextSteps,
          participants
        ),
        this.generateShareableSummary(
          transcript,
          unansweredQuestions,
          nextSteps,
          participants
        ),
      ]);

      const output: SummaryOutput = {
        internalSummary,
        unansweredQuestions,
        nextSteps,
        shareableSummary: shareableSummary.value,
        salesPipelineStatus,
      };

      return output;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "Unknown";
      console.log(error);
      this.axiom.log({
        event: "generate_summary_error",
        errorMessage: errorMessage,
        error: JSON.stringify(error),
      });
      return null;
    }
  }

  private async extractUnansweredQuestions(
    transcript: string
  ): Promise<ObjectWithItems> {
    const userPrompt = unansweredQuestionsUserPrompt(transcript);
    const response = await this.openaiCall(
      unansweredQuestionsSystemPrompt,
      userPrompt,
      ObjectWithItemsSchema,
      "unansweredQuestions"
    );
    return response;
  }

  private async extractNextSteps(transcript: string): Promise<ObjectWithItems> {
    const userPrompt = nextStepsUserPrompt(transcript);
    const response = await this.openaiCall(
      nextStepsSystemPrompt,
      userPrompt,
      ObjectWithItemsSchema,
      "nextSteps"
    );
    return response;
  }

  private async determineSalesPipelineStatus(
    transcript: string
  ): Promise<"lead" | "qualified" | "closing" | "closed"> {
    const userPrompt = salesPipelineStatusUserPrompt(transcript);
    const response = await this.openaiCall(
      salesPipelineStatusSystemPrompt,
      userPrompt,
      SalesPipelineStatusSchema,
      "salesPipelineStatus"
    );
    return response.status;
  }

  private async generateInternalSummary(
    transcript: string,
    unansweredQuestions: ObjectWithItems,
    nextSteps: ObjectWithItems,
    participants: string
  ): Promise<ObjectWithItems> {
    const userPrompt = createInternalSummaryUserPrompt(
      unansweredQuestions,
      nextSteps,
      participants,
      transcript
    );
    const response = await this.openaiCall(
      internalSummarySystemPrompt,
      userPrompt,
      ObjectWithItemsSchema,
      "internalSummary"
    );
    return response;
  }

  private async generateShareableSummary(
    transcript: string,
    unansweredQuestions: ObjectWithItems,
    nextSteps: ObjectWithItems,
    participants: string
  ): Promise<{ value: string }> {
    const userPrompt = createShareableSummaryUserPrompt(
      unansweredQuestions,
      nextSteps,
      participants,
      transcript
    );
    const response = await this.openaiCall(
      shareableSummarySystemPrompt,
      userPrompt,
      z.object({ value: z.string() }),
      "shareableSummary"
    );
    return response;
  }

  private async openaiCall<T>(
    systemPrompt: string,
    userPrompt: string,
    responseSchema: z.ZodType<T>,
    key: string
  ): Promise<T> {
    try {
      const startTime = performance.now();
      const response = await this.openai.beta.chat.completions.parse({
        model: "gpt-4o",
        messages: [
          { role: "system", content: systemPrompt },
          { role: "user", content: userPrompt },
        ],
        response_format: zodResponseFormat(responseSchema, "value"),
      });
      const endTime = performance.now();
      const duration = endTime - startTime;
      this.axiom.log({
        event: "call.summary.openai",
        duration: duration,
        key: key,
        response,
      });

      if (
        response.choices &&
        response.choices.length > 0 &&
        response.choices[0].message?.parsed
      ) {
        return response.choices[0].message.parsed;
      } else {
        throw new Error("Unexpected response format from OpenAI API");
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "Unknown";
      this.axiom.log({
        event: "call.summary.openai.error",
        errorMessage: errorMessage,
        error: JSON.stringify(error),
      });
      throw error;
    }
  }
}
