import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/hooks/use-toast";
import { useUser } from "@clerk/clerk-react";
import { Loader2 } from "lucide-react";

interface ConnectZoomProps {
  onConnect: () => void;
  authServiceUrl: string | null;
}

export function ConnectZoom({ onConnect, authServiceUrl }: ConnectZoomProps) {
  const { toast } = useToast();
  const { user, isLoaded, isSignedIn } = useUser();
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnect = () => {
    setIsConnecting(true);
    if (authServiceUrl) {
      const isDesktop = !window.location.port.match(/^(80|443|1999)$/);

      if (isDesktop && window.Neutralino) {
        window.Neutralino.os.open(authServiceUrl);
      } else {
        window.open(authServiceUrl, "_blank");
      }
    } else {
      console.error("Auth service URL is not available");
    }
  };

  useEffect(() => {
    if (isConnecting) {
      const startTime = Date.now();
      const maxDuration = 5 * 60 * 1000; // 5 minutes
      const interval = setInterval(async () => {
        if (Date.now() - startTime > maxDuration) {
          clearInterval(interval);
          setIsConnecting(false);
          toast({
            title: "Connection timeout",
            description: "Please try connecting your Zoom account again.",
            variant: "destructive",
          });
        } else if (isLoaded && isSignedIn) {
          await user?.reload();
          const zoomAccount = user?.externalAccounts?.find(
            (account) => account.provider === "custom_zoom"
          );
          if (zoomAccount) {
            clearInterval(interval);
            setIsConnecting(false);
            onConnect();
            toast({
              title: "Zoom account connected successfully",
              variant: "default",
            });
          }
        }
      }, 10000); // Check every 10 seconds

      return () => clearInterval(interval);
    }
  }, [isConnecting, user, isLoaded, isSignedIn, onConnect, toast]);

  useEffect(() => {
    toast({
      title: "Please connect your Zoom account to continue",
      action: (
        <Button
          variant="outline"
          onClick={handleConnect}
          disabled={isConnecting}
        >
          {isConnecting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Connecting...
            </>
          ) : (
            "Connect Zoom"
          )}
        </Button>
      ),
      duration: Infinity,
    });
  }, [isConnecting]);

  return null;
}
