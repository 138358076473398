import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Info } from "lucide-react";
import type { AILatencyMetrics } from "party/types";

export function AiHelpLatency({
  latencyMetrics,
}: Readonly<{ latencyMetrics: AILatencyMetrics }>) {
  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm">
            <Info size={12} />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-80 p-4">
          <div className="prose">
            <p>Total: {latencyMetrics.totalDuration}ms</p>
            <p>AI Request: {latencyMetrics.aiRequestDuration}ms</p>
            <p>Generation: {latencyMetrics.generationDuration}ms</p>
            <p>Model: {latencyMetrics.model}</p>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
