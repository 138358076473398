import { useState, useEffect, useMemo } from "react";
import { TranscriptUpdate } from "party/types";
import {
  processTranscriptItems,
  TranscriptSentence,
} from "../utils/transcriptProcessor";

// const ITEMS_TO_PROCESS_LIVE = 5;

export function useGroupedTranscript(
  transcript: TranscriptUpdate[]
): TranscriptSentence[] {
  // const [processedTranscript, setProcessedTranscript] = useState<
  //   TranscriptSentence[]
  // >([]);

  const liveProcessedTranscript = useMemo(() => {
    //const recentItems = transcript.slice(-ITEMS_TO_PROCESS_LIVE);
    const processed = processTranscriptItems(transcript);
    console.log("Grouped transcript:", processed);
    return processed;
  }, [transcript]);

  // useEffect(() => {
  //   if (transcript.length > ITEMS_TO_PROCESS_LIVE) {
  //     const itemsToProcess = transcript.slice(0, -ITEMS_TO_PROCESS_LIVE);
  //     const newProcessedTranscript = processTranscriptItems(itemsToProcess);
  //     setProcessedTranscript(newProcessedTranscript);
  //   } else {
  //     setProcessedTranscript([]);
  //   }
  // }, [transcript]);

  // return [...processedTranscript, ...liveProcessedTranscript];
  return liveProcessedTranscript;
}
