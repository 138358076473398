import { useEffect } from "react";

const NeutralinoShortcuts = () => {
  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      const isCtrlOrCmd = event.ctrlKey || event.metaKey;

      switch (event.key) {
        case "z":
          if (isCtrlOrCmd) {
            event.preventDefault();
            document.execCommand("undo");
          }
          break;
        case "c":
          if (isCtrlOrCmd) {
            try {
              await navigator.clipboard.writeText(
                window.getSelection()?.toString() || ""
              );
            } catch (err) {
              console.error("Failed to copy: ", err);
            }
          }
          break;
        case "v":
          if (isCtrlOrCmd) {
            console.log("Pasting...");
            try {
              const text = await navigator.clipboard.readText();
              document.execCommand("insertText", false, text);
            } catch (err) {
              console.error("Failed to paste: ", err);
            }
          }
          break;
        case "q":
          if (isCtrlOrCmd) {
            // Make sure Neutralino API is available
            if (window.Neutralino?.app) {
              window.Neutralino.app.exit();
            } else {
              console.warn("Neutralino API not available");
            }
          }
          break;
        // Add more shortcuts as needed
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return null; // This component doesn't render anything
};

export default NeutralinoShortcuts;
