import { Card } from "@/components/ui/card";
import { LoaderIcon, Sparkles } from "lucide-react";
import type {
  AILatencyMetrics,
  AIQuestionAnswer,
  AIStreamingStatus,
  PartialAIResponse,
  TranscriptUpdate,
} from "party/types";
import ReactMarkdown from "react-markdown";
import { AiHelpLatency } from "./AiHelpLatency";
import { AiHelpCardNextPrevious } from "./AiHelpCardNextPrevious";
import { AiHelpAsk } from "./AiHelpAsk";
import type { Question } from "party/questionsMessageTypes";
import { useEffect, useState } from "react";

export type HandleGetHelpFunctionArgs = {
  detectedQuestion?: Question;
  question?: string;
  transcriptItems?: TranscriptUpdate[];
};

export type HandleGetHelpFunction = (args: HandleGetHelpFunctionArgs) => void;
export interface AiHelpCardProps {
  help?: PartialAIResponse | AIQuestionAnswer | null;
  latencyMetrics?: AILatencyMetrics;
  handlePrevious: () => void;
  handleNext: () => void;
  currentIndex: number;
  historyLength: number;
  handleGetHelp: HandleGetHelpFunction;
  currentAiHelpRequest?: HandleGetHelpFunctionArgs | undefined;
  isStreaming: boolean;
  streamingStatus: AIStreamingStatus | undefined;
  streamingResponse: PartialAIResponse | null;
}

export function AiHelpCard({
  help,
  latencyMetrics,
  handlePrevious,
  handleNext,
  currentIndex,
  historyLength,
  handleGetHelp,
  currentAiHelpRequest,
  isStreaming,
  streamingStatus,
  streamingResponse,
}: Readonly<AiHelpCardProps>) {
  const [cachedAnswer, setCachedAnswer] = useState<string | null | undefined>(
    help?.answer
  );

  useEffect(() => {
    // Immediately update the cached answer when currentIndex changes
    setCachedAnswer(help?.answer);
  }, [currentIndex, help]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCachedAnswer(isStreaming ? streamingResponse?.answer : help?.answer);
    }, 200);

    return () => clearTimeout(timer);
  }, [isStreaming, streamingResponse, help]);
  const requestQuestionDisplay =
    currentAiHelpRequest?.question ??
    currentAiHelpRequest?.detectedQuestion?.question ??
    "Detecting the most recent question and answering it…";
  const questionDisplay = isStreaming
    ? requestQuestionDisplay
    : (streamingResponse?.question ?? help?.question);

  return (
    <Card className="p-4 w-full flex flex-col space-y-4">
      <AiHelpAsk onAskQuestion={handleGetHelp} />

      {(help || currentAiHelpRequest) && (
        <>
          <div className="flex justify-between items-center mb-2">
            <h2 className="font-bold text-md">
              {isStreaming ? (
                <LoaderIcon
                  size={16}
                  className="mr-2 inline-block animate-spin"
                />
              ) : (
                <Sparkles size={16} className="mr-2 inline-block" />
              )}{" "}
              {questionDisplay}
            </h2>
            <AiHelpCardNextPrevious
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              currentIndex={currentIndex}
              historyLength={historyLength}
              disabled={isStreaming}
            />
          </div>
          {cachedAnswer ? (
            <ReactMarkdown className="prose prose-sm max-w-none dark:prose-invert">
              {cachedAnswer}
            </ReactMarkdown>
          ) : (
            <div className="flex flex-col space-y-2">
              <div className="animate-pulse bg-slate-100 rounded">&nbsp;</div>
              <div className="animate-pulse bg-slate-100 rounded">&nbsp;</div>
            </div>
          )}
          <AiHelpStreamingStatus status={streamingStatus} />
          {latencyMetrics && <AiHelpLatency latencyMetrics={latencyMetrics} />}
        </>
      )}
    </Card>
  );
}

const AiHelpStreamingStatus = ({
  status,
}: {
  status: AIStreamingStatus | undefined;
}) => {
  const display = {
    started: "Querying",
    detecting_question: "Detecting question",
    searching_knowledge: "Searching knowledge",
    has_knowledge: "Found knowledge",
    no_knowledge: "No knowledge found",
    generating_answer: "Thinking",
    streaming: "Answering",
    done: "Done",
  };
  if (!status) return null;
  return (
    <div className="text-xs text-gray-400">{display[status] ?? "Working"}…</div>
  );
};
