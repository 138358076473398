import { Button } from "@/components/ui/button";
import { Card, CardContent, CardTitle } from "@/components/ui/card";
import { SummaryOutput } from "party/logic/SummaryGenerator/SummaryGenerator";
import ReactMarkdown from "react-markdown";

const SummarySection = ({
  title,
  items,
  value,
}: {
  title: string;
  items?: string[];
  value?: string;
  useMarkdown?: boolean;
}) => {
  return (
    <Card>
      <CardTitle className="text-md py-4 px-4 border-b border-gray-300">
        {title}
      </CardTitle>
      <CardContent className="p-4">
        {value && (
          <div className="prose">
            <ReactMarkdown>{value}</ReactMarkdown>
          </div>
        )}
        {items && items?.length === 0 && <p>None</p>}
        {items && items?.length > 0 && (
          <ul className="list-disc list-inside">
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </CardContent>
    </Card>
  );
};

const SummaryDisplay = ({ summary }: { summary: SummaryOutput }) => {
  return (
    <div className="flex flex-col space-y-2">
      <h2 className="text-lg font-semibold">
        Sales Pipeline Status: {summary.salesPipelineStatus}
      </h2>

      <SummarySection
        title="Shareable Summary"
        value={summary.shareableSummary}
        useMarkdown={true}
      />
      <SummarySection
        title="Unanswered Questions"
        items={summary.unansweredQuestions.items}
      />
      <SummarySection title="Next Steps" items={summary.nextSteps.items} />
      <SummarySection
        title="Internal Summary"
        items={summary.internalSummary.items}
      />
    </div>
  );
};
export const CallSummary = ({
  handleRequestSummary,
  summary,
}: {
  handleRequestSummary: () => void;
  summary: SummaryOutput | null;
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {summary && <SummaryDisplay summary={summary} />}
      <div>
        <Button
          onClick={() => {
            handleRequestSummary();
          }}
        >
          Request summary
        </Button>
      </div>
    </div>
  );
};
